import React from "react";
import '../About.scss'
import {Link} from "react-router-dom";

export const InnovatorsComponent = () => {

    return (
        <>
            <div id="about-page-innovators">
                <div id="about-page-innovators-text">
                    <h2>Calling All Innovators<br></br></h2>
                    <p>
                        <span data-aos="fade-up" data-aos-duration="100">You Are Needed at Rhombus<br></br></span>
                        <span data-aos="fade-up" data-aos-duration="200">Dreamers, doers, problem-solvers, systems-level thinkers,<br></br></span>
                        <span data-aos="fade-up" data-aos-duration="400">breakers of molds and rejectors of conventional wisdom – this<br></br></span>
                        <span data-aos="fade-up" data-aos-duration="500">is your moment, and Rhombus is the place for you to seize it.<br></br></span>
                        <span data-aos="fade-up" data-aos-duration="500">We are looking for people who are driven by a sense of purpose.</span>
                        <span data-aos="fade-up" data-aos-duration="600">Who want to transform the way we work and solve problems.<br></br></span>
                        <span data-aos="fade-up" data-aos-duration="700">Who don't just want a job, but want a mission.<br></br></span>
                        <span data-aos="fade-up" data-aos-duration="800">We are looking for innovators who hear our clients say, “If we can dream it, Rhombus can do it,” and who are inspired, not intimidated.<br></br></span>
                        <span data-aos="fade-up" data-aos-duration="900">Come join us at Rhombus and deliver game-changing solutions for America.<br></br><br></br></span>
                    </p>
                    <Link className="moreLinks" to='https://boards.greenhouse.io/rhombuspower'>
                        <div className="loader-line-about"></div>
                        <span className="loader-line" style={{backgroundColor: "#ffffff"}}></span>
                        <img className="loadArrow" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAaUlEQVRIie2UMQ6AIBAE9zS+1UZsfAlWPtY4FjR0YOAKE6a+MJsLt9Jg0IrVDAFB0iwpmhldEwALcJOIQFWor5IAPN6SNZNcv5bsmeQozU/dE7TguiLvx/2+aeuh1VbFplQVZ/eqGAyKvEzPp2/EZkS8AAAAAElFTkSuQmCC"></img>
                        <span style={{color: "#ffffff"}}>See Our Open Positions</span>
                    </Link>
                </div>
                <div></div>
            </div>
        </> 
    )
}