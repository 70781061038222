import React from "react";
import '../About.scss'

export const TransformingComponent = () => {

    return (
        <div id="about-page-transforming">
            <p>Rhombus is transforming the nation’s defense and national security enterprises with Guardian, our cutting-edge Artificial Intelligence platform for strategic, operational, and tactical decisions at the speed of relevance.</p>
        </div>
    )
}


