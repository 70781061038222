export const emailDomainValidator = (email) => {
   // TODO add option to switch on validation for whitelisted emails  
   let regexCheck = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
   return regexCheck
}

export const InputValidator = (props) => {
   switch(props.type){
      //TODO Comment in the conditions
      case 'email':
         return emailDomainValidator(props.value);
      case 'name':
         //TODO name conditions
         return (props.value).length>0;
      case 'password':
         return /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/.test(props.value);
      case 'retypePassword':
         return props.value.length > 0 && props.compare.length > 0 ? props.compare === props.value : true
      case 'token':
         //TODO conditions for token?
         return (props.value).length>0;
	  case 'ip':
         return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(props.value);
      default:  
         return false;
   }
}
