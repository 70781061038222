import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import logo from "./assets/img/Rhombus-Logo.png";
import { HeaderLink } from './HeaderLink';

export const Header = ({ paths }) => {
    const location = useLocation();
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [modalClass, setModalClass] = useState('')

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })

        switch (location.pathname) {
            case '/':
                document.title = 'Rhombus Power'
                break
            case '/home':
                document.title = 'Home - Rhombus Power'
                break
            case '/about':
                document.title = 'About - Rhombus Power'
                break
            case '/in-the-news':
                document.title = 'In the News - Rhombus Power'
                break
            case '/careers':
                document.title = 'Jobs at Rhombus - Rhombus Power'
                break
            case '/contact':
                document.title = 'Contact - Rhombus Power'
                break
        }
    }, [location])

    const toggleModal = () => {
        if (modalIsOpen) {
            setModalClass('close-modal')
            setModalIsOpen(false)
            document.body.style.overflow = "unset"
        } else {
            setModalClass('open-modal')
            setModalIsOpen(true)
            document.body.style.overflow = "hidden"
        }
    }
    useEffect(() => {
        if (!modalIsOpen) {
            setTimeout(() => {
                setModalClass(modalClass => {
                    if (modalClass === "close-modal") {
                        return ""
                    } else {
                        return modalClass
                    }
                })

            }, 1000)
        }
    }, [modalIsOpen])

    return (
        <>
            <div id="mobile-nav-modal" className={modalClass}>
                <ul>
                    {Object.values(paths).map((obj, i) => {
                        if (obj.name !== 'empty') {
                            if (!obj.external) {
                                return (
                                    <li key={i} onClick={toggleModal}>
                                        <HeaderLink to={obj.path} name={obj.name} />
                                    </li>
                                )
                            }
                            else {
                                return (
                                    <li key={i} onClick={toggleModal}>
                                        <a href={obj.path}>{obj.name}</a>
                                    </li>
                                )
                            }
                        }
                    })}
                </ul>
            </div>
            <header>
                <div className='header-content'>
                    <Link
                        to="/"
                        className='header-logo'
                        children={<img src={logo} />}
                    />
                    <ul id="header-nav">
                        {Object.values(paths).map((obj, i) => {
                            if (obj.name !== 'empty') {
                                if (!obj.external) {
                                    return (
                                        <li key={i}>
                                            <HeaderLink to={obj.path} name={obj.name} />
                                        </li>
                                    )
                                }
                                else {
                                    return (
                                        <li key={i}>
                                            <a href={obj.path} target="_blank" rel="noopener">{obj.name}</a>
                                        </li>
                                    )
                                }
                            }
                        })}
                    </ul>
                    <button
                        id="header-hamburger"
                        onClick={toggleModal}
                    >
                        {!modalIsOpen
                            ? <>
                                <div className='line'></div>
                                <div className='line'></div>
                                <div className='line'></div>
                            </>
                            : <>
                                <div className='crossLine'></div>
                                <div className='crossLine'></div>
                            </>
                        }
                    </button>
                </div>
            </header>
        </>
    )
}