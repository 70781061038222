

import React from "react";
import './utilStyles/AnimatedHeaderTitle.scss'

export const AnimatedHeaderTitle = (props) => {

    return (
        <div id="header">
            <h1 style={props.style}>{props.text}</h1>
        </div>   
    )
}