import { useState, useEffect } from "react";
import { TextField, EmailField } from '../utils/fields'
import { AnimatedHeaderTitle } from '../utils/AnimatedHeaderTitle'
import { Helmet } from "react-helmet";
import { useLocation, Navigate } from "react-router-dom";
import axios from 'axios';
import './ThankYou.scss';


export const ThankYou = () => {

    const location = useLocation();

    if(location.state ==='contact') {
        return (
            <div id="thankyou-page">
                <Helmet>
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Thank You" />
                    <meta property="og:url" content="./thank-you/" />
                    <meta property="og:site_name" content="Rhombus Power" />
                    <meta property="article:modified_time" content="2022-03-31T12:16:16+00:00" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:label1" content="Est. reading time" />
                    <meta name="twitter:data1" content="2 minutes" />
                </Helmet>
                {/* <AnimatedHeaderTitle text="Contact Rhombus"></AnimatedHeaderTitle> */}
                <div id="contact-page-content">
                    <div id="contact-description">
                        <div id="contact-slide-in">
                            <div className="thankyouMessage">
                                <h2>Thank you for reaching out to us!</h2>
                                <h3>We will respond to you shortly.</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return (
            <Navigate to="/" />
        )
    }

    
}

