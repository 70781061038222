import React from "react";
import {Grid, Column} from "@carbon/react";
import { Helmet } from "react-helmet";
import './About.scss'


import { TodayComponent, AtRhombusComponent, InnovatorsComponent, TransformingComponent } from './aboutSubs'
import { YellowDivider, Mp4VideoComponent, AnimatedHeaderTitle, GetInTouch } from '../utils'

export const About = () => {
    return (
        <div id="about-page">
            <Helmet>
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="About - Rhombus Power" />
                <meta property="og:url" content="./about/" />
                <meta property="og:site_name" content="Rhombus Power" />
                <meta property="article:modified_time" content="2022-03-30T21:01:06+00:00" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:label1" content="Est. reading time" />
                <meta name="twitter:data1" content="4 minutes" />
            </Helmet>
            <AnimatedHeaderTitle text="Our Mission"/>
            <Mp4VideoComponent url="videos/Rhombus-Michigan-3-4.mp4" />
            <TransformingComponent/>
            <YellowDivider/>
            <TodayComponent/>
            <AtRhombusComponent/>
            <InnovatorsComponent/>
            <GetInTouch color='#0026FF'/>
        </div>
    )
}