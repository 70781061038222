
import React from "react";
import '../Home.scss'

export const AmericaStrongerComponent = () => {

    return (
        <>
            <div id="home-page-americastronger">
                <div id="home-page-americastronger-img-cont">
                    <div id="home-page-americastronger-img">
                        <div data-aos="fade-up" data-aos-duration="500"></div>
                    </div>
                </div>
                
                <div id="home-page-americastronger-text-cont">
                    <div>
                        <h2 data-aos="fade-up" data-aos-duration="500">Rhombus Makes America Stronger</h2>
                        <p>
                            Rhombus is transforming the nation’s defense and national security enterprises with Guardian, our cutting-edge Artificial Intelligence platform for strategic, operational, and tactical decisions at the speed of relevance.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
