import React from "react";
import { Grid, Column } from "@carbon/react";
import { Helmet } from "react-helmet";
import './PrivacyPolicy.scss'
import { AnimatedHeaderTitle } from "../utils";


export const PrivacyPolicy = () => {
    return (
        <div id="privacy-policy-page">
            <Helmet>
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Privacy Policy - Rhombus Power" />
                <meta property="og:url" content="./privacy-policy/" />
                <meta property="og:site_name" content="Rhombus Power" />
                <meta property="article:modified_time" content="2022-08-04T12:16:16+00:00" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:label1" content="Est. reading time" />
                <meta name="twitter:data1" content="15 minutes" />
            </Helmet>
            <div className="privacy-policy">
                <div id="privacy-policy-header">
                    <h1>Our Privacy Policy</h1>
                    <p id="last-updated">Last updated on Aug 4, 2022</p>
                </div>
                <p>
                    Thank you for choosing to be part of our community of brands at Rhombus Power (("Rhombus", "Company", "we", "us", or "our"). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice or our practices concerning your personal information, please get in touch with us at contactus@rhombuspower.com.
                </p>
                <div className="privacy-notice">
                    <p>This privacy notice describes how we might use your information if you:</p>
                    <ul>
                        <li>Visit our website at rhombuspower.com.</li>
                        <li>Engage with us in other related ways ― including any sales, marketing, or event</li>
                    </ul>
                    <p>In this privacy notice, if we refer to:</p>
                    <ul>
                        <li>"Website" we are referring to any website of ours that references or links to this policy</li>
                        <li>"Services" we are referring to our website and other related services, including any sales, marketing, or events</li>
                    </ul>
                </div>
                <p>
                    The purpose of this privacy notice is to explain to you in the most transparent way possible what information we collect, how we use it, and what rights you have concerning it. If there are any terms in this privacy notice that you disagree with, please discontinue use of our Services immediately.
                    Please read this privacy notice carefully, as it will help you understand what we do with the information that we collect.
                </p>
                <div id="privacy-policy-table-of-contents">
                    <h3>TABLE OF CONTENTS</h3>
                    <div><a href="#info-collect">1. WHAT INFORMATION DO WE COLLECT?</a></div>
                    <div><a href="#info-use">2. HOW DO WE USE YOUR INFORMATION?</a></div>
                    <div><a href="#info-share">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a></div>
                    <div><a href="#info-share-who">4. WHO WILL YOUR INFORMATION BE SHARED WITH?</a></div>
                    <div><a href="#cookies">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a></div>
                    <div><a href="#intl-transfer">6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</a></div>
                    <div><a href="#info-keep">7. HOW LONG DO WE KEEP YOUR INFORMATION?</a></div>
                    <div><a href="#info-safe">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</a></div>
                    <div><a href="#info-minors">9. DO WE COLLECT INFORMATION FROM MINORS?</a></div>
                    <div><a href="#privacy-rights">10. WHAT ARE YOUR PRIVACY RIGHTS?</a></div>
                    <div><a href="#do-not-track">11. CONTROLS FOR DO-NOT-TRACK FEATURES</a></div>
                    <div><a href="#ca-residents">12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a></div>
                    <div><a href="#updates">13. DO WE MAKE UPDATES TO THIS NOTICE?</a></div>
                    <div><a href="#data-review">14. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?</a></div>
                    <div><a href="#contact">15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></div>
                </div>
                <div className="privacy-policy-section" id="info-collect">
                    <h3>1. WHAT INFORMATION DO WE COLLECT?</h3>
                    <p className="in-short">
                        In Short: We collect personal information that you provide to us.
                    </p>
                    <p>
                        We collect personal information that you voluntarily provide to us when you register on the website, express an interest in obtaining information about us or our products and services when you participate in activities on the website or otherwise when you contact us.
                        The personal information that we collect depends on the context of your interactions with us and the website, the choices you make, and the products and features you use. The personal information we collect may include the following:
                    </p>
                    <p>
                        Personal Information Provided by You. We collect names, phone numbers, email addresses, mailing addresses, passwords, contact preferences, usernames, contact or authentication data, billing addresses, debit/credit card numbers, job titles, and other similar information.
                        All personal information you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
                    </p>
                    <p>Information Automatically Collected</p>
                    <p className="in-short">
                        In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our website.
                    </p>
                    <p>
                        We automatically collect certain information when you visit, use, or navigate the website. This information does not reveal your specific identity (like your name or contact information). Still, it may include device and usage information, such as your IP address, browser, device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our website, and other technical information. This information is primarily needed to maintain the security and operation of our website and for our internal analytics and reporting purposes.
                    </p>
                    <p>
                        Like many businesses, we also collect information through cookies and similar technologies.
                        The information we collect includes:
                    </p>
                    <div className="list-items">
                        <ul>
                            <li>Log and Usage Data. Log and usage data are service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our website and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings, and information about your activity on the website (such as the date/time stamps associated with your usage, pages, files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps') and hardware settings).</li>
                            <li>Device Data. We collect device data such as information about your computer, phone, tablet, or other devices you use to access the website. Depending on the device used, this device data may include your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</li>
                            <li>Location Data. We collect location data, such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the website. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt-out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. Note, however, if you choose to opt-out, you may not be able to use certain aspects of the services.</li>
                        </ul>
                    </div>

                    <p>Information Collected From Other Sources</p>
                    <p className="in-short">
                        In Short: We may collect limited data from public databases, marketing partners, and other outside sources.
                    </p>
                    <p>
                        To enhance our ability to provide relevant marketing, offers, and services to you and update our records, we may obtain information about you from other sources, such as public databases, joint marketing partners, affiliate programs, data providers, as well as from other third parties. This information includes mailing addresses, job titles, email addresses, phone numbers, intent data (or user behavior data), Internet Protocol (IP) addresses, social media profiles, social media URLs, and custom profiles for purposes of targeted advertising and event promotion.
                    </p>
                </div>
                <div className="privacy-policy-section" id="info-use">
                    <h3>2. HOW DO WE USE YOUR INFORMATION?</h3>
                    <p className="in-short">
                        In Short: We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.
                    </p>
                    <p>
                        We use personal information collected via our website for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.
                    </p>
                    <p>We use the information we collect or receive:</p>
                    <div className="list-items">
                        <ul>
                            <li>To facilitate account creation and logon process. Suppose you choose to link your account with us to a third-party account (such as your Google or Facebook account). In that case, we use the information you allowed us to collect from those third parties to facilitate account creation and logon process for the performance of the contract.</li>
                            <li>To post testimonials. We post testimonials on our website that may contain personal information. Prior to posting a testimonial, we will obtain your consent to use your name and the content of the testimonial. If you wish to update or delete your testimonial, please get in touch with us at contactus@rhombuspower.com and be sure to include your name, testimonial location, and contact information.</li>
                            <li>Request feedback. We may use your information to request feedback and to contact you about your use of our website.</li>
                            <li>To enable user-to-user communications. We may use your information to enable user-to-user communications with each user's consent.</li>
                            <li>To manage user accounts. We may use your information to manage our account and keep it in working order.</li>
                            <li>To send administrative information to you. We may use your personal information to send you a product, service, and new feature information, and/or information about changes to our terms, conditions, and policies.</li>
                            <li>To protect our Services. We may use your information as part of our efforts to keep our website safe and secure (for example, for fraud monitoring and prevention).</li>
                            <li>To enforce our terms, conditions, and policies for business purposes, to comply with legal and regulatory requirements, or in connection with our contract.</li>
                            <li>To respond to legal requests and prevent harm. If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.</li>
                            <li>Fulfill and manage your orders. We may use your information to fulfill and manage your orders, payments, returns, and exchanges made through the website.</li>
                            <li>Administer prize draws and competitions. We may use your information to administer prize draws and competitions when you elect to participate in our competitions.</li>
                            <li>To deliver and facilitate the delivery of services to the user. We may use your information to provide you with the requested service.l</li>
                            <li>To respond to user inquiries/offer support to users. We may use your information to respond to your inquiries and solve any potential issues you might have with the use of our Services.</li>
                            <li>To send you marketing and promotional communications. We and/or our third-party marketing partners may use the personal information you send to us for our marketing purposes if this is in accordance with your marketing preferences. For example, we will collect personal information from you when expressing an interest in obtaining information about us or our website, subscribing to marketing, or otherwise contacting us. You can opt out of our marketing emails at any time (see <a href="#privacy-rights">WHAT ARE YOUR PRIVACY RIGHTS?</a> below).</li>
                            <li>Deliver targeted advertising to you. We may use your information to develop and display personalized content and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness.</li>
                        </ul>
                    </div>
                </div>
                <div className="privacy-policy-section" id="info-share">
                    <h3>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h3>
                    <p className="in-short">
                        In Short: We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.
                    </p>
                    <p>
                        We may process or share the data that we hold based on the following legal basis:
                    </p>
                    <div className="list-items">
                        <ul>
                            <li>Consent: We may process your data if you have given us specific permission to use your personal information for a specific purpose.</li>
                            <li>Legitimate Interests: We may process your data as reasonably necessary to achieve our legitimate business interests.</li>
                            <li>Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</li>
                            <li>Legal Obligations: We may disclose your information where we are legally required to do so to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal processes, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</li>
                            <li>Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</li>

                        </ul>
                    </div>
                    <p>
                        More specifically, we may need to process your data or share your personal information in the following situations:
                    </p>
                    <div className="list-items">
                        <ul>
                            <li>Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                            <li>Vendors, Consultants, and Other Third-Party Service Providers. We may share your data with third-party vendors, service providers, contractors, or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include payment processing, data analysis, email delivery, hosting services, customer service, and marketing efforts. We may allow selected third parties to use tracking technology on the website, which will enable them to collect data on our behalf about how you interact with our website over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content, pages, or features, and better understand online activity. Unless described in this notice, we do not share, sell, rent, or trade any of your information with third parties for their promotional purposes. We have contracts in place with our data processors designed to help safeguard your personal information. This means that they cannot do anything with your personal information unless we have instructed them to do it. They will also not share your personal information with any organization apart from us. They also commit to protect the data they hold on our behalf and to retain it for the period we instruct.
                            </li>
                            <li>Affiliates. We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or are under common control.</li>
                        </ul>
                    </div>
                </div>
                <div className="privacy-policy-section" id="info-share-who">
                    <h3>4. WHO WILL YOUR INFORMATION BE SHARED WITH?</h3>
                    <p className="in-short">
                        In Short: We only share information with the following categories of third parties.
                    </p>
                    <p>
                        We only share and disclose your information with the following categories of third parties. If we have processed your data based on your consent and you wish to revoke your consent, please contact us using the contact details provided in the section below titled <a href="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
                    </p>
                    <div className='list-items'>
                        <ul>
                            <li>Ad Networks</li>
                            <li>Affiliate Marketing Programs</li>
                            <li>Cloud Computing Services</li>
                            <li>Communication & Collaboration Tools</li>
                            <li>Data Analytics Services</li>
                            <li>Data Storage Service Providers</li>
                            <li>Finance & Accounting Tools</li>
                            <li>Government Entities</li>
                            <li>Order Fulfillment Service Provider</li>
                            <li>Payment Processors</li>
                            <li>Performance Monitoring Tools</li>
                            <li>Product Engineering & Design Tools</li>
                            <li>Retargeting Platforms</li>
                            <li>Sales & Marketing Tools</li>
                            <li>Social Networks</li>
                            <li>Testing Tools</li>
                            <li>User Account Registration & Authentication Services</li>
                            <li>Website Hosting Service Providers</li>
                        </ul>
                    </div>
                </div>
                <div className="privacy-policy-section" id="cookies">
                    <h3>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h3>
                    <p className="in-short">
                        In Short: We may use cookies and other tracking technologies to collect and store your information.
                    </p>
                    <p>
                        We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Our cookie notice sets out specific information about how we use such technologies and how you can refuse certain cookies.
                    </p>
                </div>
                <div className="privacy-policy-section" id="intl-transfer">
                    <h3>6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h3>
                    <p className="in-short">
                        In Short: We may transfer, store, and process your information in countries other than your own.
                    </p>
                    <p>
                        Our servers are located in the United States. If you are accessing our website from outside the United States, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see <a href="#info-share">WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a> above), in the United States, and other countries.
                        Suppose you are a European Economic Area (EEA) or the United Kingdom (UK) resident. In that case, these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. However, we will take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.
                    </p>
                    <p>European Commission's Standard Contractual Clauses:
                        We have implemented measures to protect your personal information, including using the European Commission's Standard Contractual Clauses to transfer personal information between our group companies and between our third-party providers. These clauses require all recipients to protect all personal information that they process originating from the EEA or UK in accordance with European data protection laws and regulations. Our Standard Contractual Clauses can be provided upon request. We have implemented appropriate safeguards with our third-party service providers and partners, and further details can be provided upon request.
                    </p>
                </div>
                <div className="privacy-policy-section" id="info-keep">
                    <h3>7. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
                    <p className="in-short">
                        In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.
                    </p>
                    <p>
                        We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us to keep your personal information for longer than the period in which users have an account with us.
                        When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information or, if this is not possible (for example, because your personal information has been stored in backup archives). We will securely store your personal information and isolate it from any further processing until deletion is possible.
                    </p>
                </div>
                <div className="privacy-policy-section" id="info-safe">
                    <h3>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>
                    <p className="in-short">
                        In Short: We aim to protect your personal information through a system of organizational and technical security measures.
                    </p>
                    <p>
                        We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, the transmission of personal information to and from our website is at your own risk. You should only access the website within a secure environment.
                    </p>
                </div>
                <div className="privacy-policy-section" id="info-minors">
                    <h3>9. DO WE COLLECT INFORMATION FROM MINORS?</h3>
                    <p className="in-short">
                        In Short: We do not knowingly collect data from or market to children under 13 years of age.
                    </p>
                    <p>
                        We do not knowingly solicit data from or market to children under 13 years of age. By using the website, you represent that you are at least 13 or that you are the parent or guardian of such a minor and consent to such minor dependent's use of the website. If we learn that personal information from users less than 13 years of age has been collected, we will deactivate the account and take reasonable measures to delete such data from our records promptly. If you become aware of any data we may have collected from children under age 13, please contact us at contactus@rhombuspower.com.
                    </p>
                </div>
                <div className="privacy-policy-section" id="privacy-rights">
                    <h3>10. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
                    <p className="in-short">
                        In Short: In some regions, such as the European Economic Area (EEA) and the United Kingdom (UK), you have rights that allow greater access to and control your personal information. You may review, change, or terminate your account at any time.
                    </p>
                    <p>
                        In some regions (like the EEA and UK), you have certain rights under applicable data protection laws. These may include the right:
                    </p>
                    <div className="list-items">
                        <ol>
                            <li>
                                To request access and obtain a copy of your personal information.
                            </li>
                            <li>
                                To request rectification or erasure.
                            </li>
                            <li>
                                To restrict the processing of your personal information.
                            </li>
                            <li>
                                If applicable, to data portability.
                            </li>
                            <p>In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the contact details provided below. We will consider and act upon any request by applicable data protection laws.</p>
                            <p>If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. However, please note that this will not affect the lawfulness of the processing before its withdrawal, nor will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>
                            <p>Suppose you are a resident in the EEA or UK, and you believe we are unlawfully processing your personal information. In that case, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.</p>
                            <p>If you are a resident in Switzerland, the contact details for the data protection authorities are available here: <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.</p>
                            <p>If you have questions or comments about your privacy rights, you may email us at contactus@rhombuspower.com.</p>
                            <p>Account Information</p>
                            <p>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
                            <p>
                                Log in to your account settings and update your user account.
                                Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use, and/or comply with applicable legal requirements.

                            </p>
                            <p>
                                Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you prefer, you can usually set your browser to remove cookies and reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our website.
                                To opt out of interest-based advertising by advertisers on our website, visit <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a>.
                            </p>
                            <p>
                                Opting out of email marketing: You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list. However, we may still communicate with you, for example, to send you service-related emails that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes. To otherwise opt-out, you may:
                                Access your account settings and update your preferences.
                            </p>
                        </ol>
                    </div>
                </div>
                <div className="privacy-policy-section" id="do-not-track">
                    <h3>11. CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
                    <p>
                        Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
                    </p>
                </div>
                <div className="privacy-policy-section" id="ca-residents">
                    <h3>12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h3>
                    <p className="in-short">
                        In Short: Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.
                    </p>
                    <p>
                        California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
                    </p>
                    <p>
                        If you are under 18 years of age, reside in California, and have a registered account with the website, you have the right to request the removal of unwanted data that you publicly post on the website. To request the removal of such data, please contact us using the contact information provided below. Include the email address associated with your account and a statement that you reside in California. We will ensure the data is not publicly displayed on the website, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).
                    </p>

                </div>
                <div className="privacy-policy-section" id="updates">
                    <h3>13. DO WE MAKE UPDATES TO THIS NOTICE?</h3>
                    <p className="in-short">
                        In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.
                    </p>
                    <p>
                        We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date, and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
                    </p>
                </div>
                <div className="privacy-policy-section" id="data-review">
                    <h3>14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h3>
                    <p>
                        Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please contact us at contactus@rhombuspower.com. We will complete your request within 90 days of receiving it.
                    </p>
                </div>
                <div className="privacy-policy-section" id="contact">
                    <h3>15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h3>
                    <p>
                        If you have questions or comments about this notice, you may email us at contactus@rhombuspower.com or by post to:
                    </p>
                    <p>
                        Rhombus Power <br/>
                        3200 Hillview Ave <br/>
                        Stanford Research Park <br/>
                        Palo Alto, CA 94304
                    </p>
                </div>
            </div>
        </div>
    )
}