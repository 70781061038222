/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import './Accordion.scss'

export const AccordionComponent = ({ data }) => {
    const [activeContent, setActiveContent] = useState(0)
    const acc = []
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000)

    const checkViewWidth = (e) => {
        const viewWidth = e.target.innerWidth;
        if (viewWidth > 999) {
            setIsMobile(false);
        } else if (viewWidth < 1000) {
            setIsMobile(true);
        }
    }

    useEffect(()=>{
        addEventListener('resize', checkViewWidth);
        return removeEventListener('resize', checkViewWidth);
    }, [])

    const handleAccordionClick = (e) => {
        e.preventDefault();
        if (isMobile && e.target.getAttribute('id').includes('#')) {
            const accordion = e.target;
            const header = document.getElementsByTagName("header")[0];
            const prevActive = document.getElementsByClassName("active")[0];
            const currId = parseInt(accordion.getAttribute('id').replace('#', ''))
            const prevId = parseInt(prevActive.previousElementSibling.firstChild.getAttribute('id').replace('#', ''))

            accordion.scrollIntoView({
                behavior: 'smooth',
            });

            var scrolledY = window.scrollY;
            if(scrolledY && currId > prevId) {
                window.scrollTo({
                    top: accordion.getBoundingClientRect().top + window.scrollY - header.clientHeight - prevActive.clientHeight - accordion.clientHeight,
                    left: 0,
                    behavior: 'smooth'
                })
            } else if (scrolledY && currId < prevId) {
                window.scrollTo({
                    top: accordion.getBoundingClientRect().top + window.scrollY - header.clientHeight,
                    left: 0,
                    behavior: 'smooth'
                })
            }
        }
    }

    data.forEach((element) => {
        acc.push(
            <div key={element.key} className="accordion-item" >
                <a 
                    href={`${isMobile ? `#${element.key}` : "#"}`} 
                    onClick={(e) => {handleAccordionClick(e)}}
                >
                    <p 
                        id={`${isMobile ? `#${element.key}` : "#"}`} 
                        onClick={ (e) => { 
                            let activeTab = parseInt(( e.currentTarget.className ).slice(-1));
                            setActiveContent(activeTab); 
                        } } 
                        className={`accordion-title ${activeContent === element.key ? "highlight" : "nohighlight"} ${element.key}`}
                    >
                        {element.title}
                    </p>
                </a>
                <div 
                    key={element.key} 
                    className={`accordion-content ${activeContent === element.key ? "active" : "closed"}`} 
                >
                    <p className='p1 mb-4'>{element.content1}</p>
                    <p className='p2'>{element.content2}</p>
                </div>
            </div>
        )
    })

    return (
        <>
            {acc}  
        </>             
    );
};
