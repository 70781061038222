

import React from "react";
import './utilStyles/GetInTouch.scss'

export const GetInTouch = ({color='rgb(0,0,0)'}) => {
    return (
        <div id="get-in-touch-section">
            <div id="get-in-touch-1">
                <h2 data-aos="fade-up" data-aos-duration="500">Get in Touch</h2>
                <a href="./contact"><p data-aos="fade-up" data-aos-duration="600" style={{backgroundColor:color}}>Contact Us</p></a>
            </div>
        </div>
    )      
}