import { 
    Home, 
    About, 
    InTheNews, 
    Contact, 
    PrivacyPolicy
} from "../pages";

const pathType = {
    open: 'open',
    close: 'close'
}

export const paths = {
    'home': {
        path: "/",
        element: <Home />,
        pathType: pathType.open,
        name: 'Home',
        external:false,
    },
    'about': {
        path: "/about",
        element: <About />,
        pathType: pathType.open,
        name: 'About',
        external:false,
    },
    'inTheNews': {
        path: "/in-the-news",
        element: <InTheNews />,
        pathType: pathType.open,
        name: 'In the News',
        external:false,
    },
    'careers': {
        path: "https://boards.greenhouse.io/rhombuspower",
        element: <></>,
        pathType: pathType.open,
        name: 'Careers',
        external:true
    },
    'contact': {
        path: "/contact",
        element: <Contact />,
        pathType: pathType.open,
        name: 'Contact',
        external:false,
    }, 
    'empty': {
        path:'*', 
        element: <></>,
        name: 'empty',
    }
}

export const footerPaths = {
    'privacyPolicy': {
        path: "/privacy",
        element: <PrivacyPolicy />,
        pathType: pathType.open,
        name: 'Privacy Policy',
        external:false,
    },
}

export const oldPaths = [
    // {
    //     path:'/about/', 
    //     newPath: '/about'
    // }, 
    // {
    //     path:'/contact/', 
    //     newPath:'/contact'
    // }, 
    // {
    //     path:'/in-the-news/', 
    //     newPath:'/in-the-news'
    // }, 
    {
        path:'/rhombus-powers-rama-nemani-awarded-nasas-highest-honor/', 
        newPath:'/article/2'
    }, 
    {
        path:'/the-low-carbon-path-is-paved-by-ai-innovation/', 
        newPath:'/article/3'
    }, 
    {
        path:'/defense-news-these-seven-principles-could-help-dod-acquisition-in-the-face-of-the-china-threat/', 
        newPath:'/article/1'
    }
]