import React from "react";
import "./LatestNews.scss";
import { LatestNewsData } from "./LatestNewsData";
import {ArticleSummary} from "../../InTheNews/inthenewsSubs/ArticleSummary";
import {Col, Row, Container} from 'react-bootstrap';
import {Link} from "react-router-dom";

export const LatestNews = () => {
    return (
        <>
            <div className="latestNewsRoot">
                <div className="latestContent">
                    <Container>
                        <Row>
                            <Col>
                                <div className="latestTitle">
                                    <div>Latest News</div>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ padding: '40px 0'}}>
                            {LatestNewsData.map((elem, i) => {
                                if (i === 0) {
                                    return (
                                        <Col key={i} xs="12" md="12" lg="4">
                                            <ArticleSummary
                                                img={elem.img}
                                                description={elem.description}
                                                url={elem.link}
                                                external={elem.external}
                                                newTab={true}
                                                category={elem.category}
                                                row={elem.row}
                                            />
                                        </Col>
                                    );
                                } else {
                                    return (
                                        <Col key={i} xs="12" md="6" lg="4">
                                            <ArticleSummary
                                                img={elem.img}
                                                description={elem.description}
                                                url={elem.link}
                                                external={elem.external}
                                                newTab={true}
                                                category={elem.category}
                                                row={elem.row}
                                            />
                                        </Col>
                                    );
                                }
                            })}
                        </Row>
                        <Row>
                            <Col>
                                <Link className="moreLinks" to="/in-the-news">
                                    <div className="loader-line-home"></div>
                                    <span
                                        className="loader-line"
                                        style={{ backgroundColor: '#f2cc37' }}
                                    ></span>
                                    <img
                                        className="loadArrow"
                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAArklEQVRIie2UPQ6CQBCFv/Gnk8uIstyImIiiJzE0hiMhu4fZYGJhMVYmdiwYqPjqffN23mQGZmYmwds0984UqkhfbadAbbxuWb2ApUC5ietCBA01WHT+YOfeilwAVTg9nbn16ST4YdskmYrcAUGoom19COmkV6ZDTDoj+iXaPypFCkBRstaZa5eml8EQRo9o9CEHReRtmn+LC5ShxWGCRQvCN8nRW3MecipmZv7nA+bmUNk/H3f1AAAAAElFTkSuQmCC"
                                    ></img>
                                    <span className="exp-more-text">
                                        Explore More Recent News
                                    </span>
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}