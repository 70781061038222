import React, {
    useEffect,
    useState,
    useReducer,
    useRef,
    useCallback,
} from 'react';
import ReactPlayer from 'react-player';

import { VideoAction } from './VideoAction';
import { Controller } from './Controller';

import './VideoWithController.scss';

const initialState = {
    playing: true,
    muted: false,
    volume: 0.5,
    userSetVolume: 0.5,
    loadedSeconds: 0,
    playedSeconds: 0,
    playbackRate: 1,
    totalSeconds: 0,
    pip: false,
};

const videoActions = Object.freeze({
    playPause: 'play/pause',
    mute: 'mute',
    volumeStep: 'volume-step',
    setValue: 'set-value',
});
const screenTypes = Object.freeze({
    init: null,
    mobile: 'mobile',
    desktop: 'desktop',
});

const stateReducer = (state, action) => {
    switch (action.type) {
        case videoActions.playPause:
            return {
                ...state,
                playing: !state.playing,
            };
        case videoActions.mute:
            if (state.volume === 0 && state.userSetVolume !== 0) {
                return {
                    ...state,
                    volume: state.userSetVolume,
                    muted: false,
                };
            }
            return {
                ...state,
                muted: true,
                volume: 0,
            };
        case videoActions.volumeStep:
            if (action.payload > 0) {
                const newVolume = Math.min(1, state.volume + action.payload);
                return {
                    ...state,
                    volume: newVolume,
                    userSetVolume: newVolume,
                    muted: false,
                };
            } else {
                const newVolume = Math.max(0, state.volume + action.payload);
                return {
                    ...state,
                    volume: newVolume,
                    userSetVolume: newVolume,
                    muted: state.muted || newVolume === 0,
                };
            }
        case videoActions.setValue:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return {
                ...state,
            };
    }
};

const useEventListener = (eventName, listenerCallback, enabled = true) => {
    useEffect(() => {
        if (enabled) {
            document.addEventListener(eventName, listenerCallback);
        }
        return () => {
            document.removeEventListener(eventName, listenerCallback);
        };
    }, [eventName, listenerCallback, enabled]);
};

export const VideoWithController = ({
    playVideo = false,
    reactPlayerProps = {},
}) => {
    const [showController, setShowController] = useState(false);
    const [videoActionType, setVideoActionType] = useState('');
    const [showVideoAction, setShowVideoAction] = useState(false);
    const [fullscreen, setFullscreen] = useState(screenTypes.init);
    const [videoState, dispatchVideoState] = useReducer(
        stateReducer,
        initialState
    );
    const player = useRef(null);
    const playerContainer = useRef(null);
    const actionTimer = useRef(null);
    const currentSeconds = useRef(videoState.playedSeconds);
    currentSeconds.current = videoState.playedSeconds;

    const updateFullscreen = useCallback(() => {
        if (document.fullscreenElement) {
            setFullscreen(
                checkIsMobile() ? screenTypes.mobile : screenTypes.desktop
            );
        } else {
            setFullscreen(screenTypes.init);
        }
    }, []);

    const exitPip = useCallback(() => {
        dispatchVideoState({
            type: videoActions.setValue,
            payload: { pip: false },
        });
    }, []);

    const requestFullscreen = useCallback(() => {
        try {
            if (document.fullscreenElement) {
                document.exitFullscreen();
                return;
            }
            if (
                document.webkitDisplayingFullscreen &&
                playerContainer.current.webkitExitFullscreen
            ) {
                playerContainer.current.webkitExitFullscreen();
                return;
            }
            if (checkIsMobile()) {
                const videoElement =
                    player.current?.player?.player?.player || {};
                handleRequestFullscreen(videoElement);
            } else {
                handleRequestFullscreen(playerContainer.current);
            }
        } catch (error) {
            console.error(error);
        }
    }, []);

    const displayAction = useCallback((action) => {
        setVideoActionType(action);
        setShowVideoAction(true);
        if (actionTimer.current !== null) {
            clearTimeout(actionTimer.current);
        }

        actionTimer.current = setTimeout(() => {
            setShowVideoAction(false);
            actionTimer.current = null;
        }, 750);
    }, []);

    const keyboardControls = useCallback(
        (evt) => {
            switch (evt.key) {
                case 'k':
                    dispatchVideoState({ type: videoActions.playPause });
                    displayAction('play');
                    break;
                case ' ':
                case 'Enter':
                    if (evt.target.nodeName === 'VIDEO') {
                        dispatchVideoState({ type: videoActions.playPause });
                        displayAction('play');
                        evt.preventDefault();
                        evt.stopPropagation();
                    }
                    break;
                case 'f':
                    requestFullscreen();
                    break;
                case 'm':
                    dispatchVideoState({ type: videoActions.mute });
                    displayAction('mute');
                    break;
                case 'ArrowUp':
                    if (evt.target.attributes?.role !== 'menuitem') {
                        dispatchVideoState({
                            type: videoActions.volumeStep,
                            payload: 0.05,
                        });
                        displayAction('volumeUp');
                    }
                    break;
                case 'ArrowDown':
                    if (evt.target.attributes?.role !== 'menuitem') {
                        dispatchVideoState({
                            type: videoActions.volumeStep,
                            payload: -0.05,
                        });
                        displayAction('volumeDown');
                    }
                    break;
                case 'ArrowRight':
                    player.current.seekTo(
                        currentSeconds.current + 5,
                        'seconds'
                    );
                    displayAction('forward');
                    break;
                case 'ArrowLeft':
                    player.current.seekTo(
                        currentSeconds.current - 5,
                        'seconds'
                    );
                    displayAction('backward');
                    break;
                default:
                    break;
            }
        },
        [requestFullscreen, displayAction]
    );

    useEventListener('fullscreenchange', updateFullscreen);
    useEventListener('leavepictureinpicture', exitPip);
    useEventListener('keyup', keyboardControls, playVideo);

    useEffect(() => {
        if (playVideo && checkIsMobile()) {
            dispatchVideoState({
                type: videoActions.setValue,
                payload: { volume: 1, userSetVolume: 1 },
            });
        }

        dispatchVideoState({
            type: videoActions.setValue,
            payload: { playing: playVideo },
        });
    }, [playVideo]);

    const controllerClassnames = [
        showController || !videoState.playing ? 'active' : '',
        fullscreen === screenTypes.desktop ? 'fullscreen' : '',
        checkIsMobile() ? 'hidden' : '',
    ];

    return (
        <div
            className="video-controller"
            id="modalContent"
            onMouseEnter={() => setShowController(true)}
            onMouseLeave={() => setShowController(false)}
            onFocus={() => setShowController(true)}
            onBlur={() => setShowController(false)}
            ref={playerContainer}
        >
            <VideoAction
                isVisible={showVideoAction}
                action={videoActionType}
                muted={videoState.muted}
                playing={videoState.playing}
                volume={videoState.volume}
            />
            <ReactPlayer
                className="video-player"
                config={{
                    file: {
                        attributes: {
                            poster: 'assets/Logos/videoThumbnail.png',
                        },
                    },
                }}
                ref={player}
                playing={videoState.playing}
                volume={videoState.volume}
                muted={videoState.muted}
                playbackRate={videoState.playbackRate}
                pip={videoState.pip}
                controls={fullscreen === screenTypes.mobile}
                onClick={() => {
                    dispatchVideoState({ type: videoActions.playPause });
                    displayAction('play');
                }}
                onDuration={(duration) => {
                    dispatchVideoState({
                        type: videoActions.setValue,
                        payload: {
                            totalSeconds: duration,
                        },
                    });
                }}
                onProgress={(progress) => {
                    dispatchVideoState({
                        type: videoActions.setValue,
                        payload: {
                            playedSeconds: progress.playedSeconds,
                            loadedSeconds: progress.loadedSeconds,
                        },
                    });
                }}
                {...reactPlayerProps}
            />
            {fullscreen !== screenTypes.mobile ? (
                <Controller
                    className={controllerClassnames.join(' ')}
                    volume={videoState.volume}
                    playing={videoState.playing}
                    playbackRate={videoState.playbackRate}
                    playedSeconds={videoState.playedSeconds}
                    totalSeconds={videoState.totalSeconds}
                    muted={videoState.muted}
                    hideOverflowMenu={fullscreen !== screenTypes.init}
                    onFocus={() => setShowController(true)}
                    onBlur={() => setShowController(false)}
                    onPlay={() => {
                        dispatchVideoState({ type: videoActions.playPause });
                    }}
                    onVolume={(evt) => {
                        const numVolume = Number(evt.target.value) / 100;
                        if (numVolume === 0) {
                            dispatchVideoState({
                                type: videoActions.setValue,
                                payload: {
                                    volume: numVolume,
                                    muted: true,
                                },
                            });
                        } else {
                            dispatchVideoState({
                                type: videoActions.setValue,
                                payload: {
                                    volume: numVolume,
                                    userSetVolume: numVolume,
                                    muted: false,
                                },
                            });
                        }
                    }}
                    onMute={() =>
                        dispatchVideoState({ type: videoActions.mute })
                    }
                    onSeek={(e) => {
                        const newProgess = Number(e.target.value);
                        player.current.seekTo(newProgess, 'seconds');
                        dispatchVideoState({
                            type: videoActions.setValue,
                            payload: { playedSeconds: newProgess },
                        });
                    }}
                    onFullscreen={requestFullscreen}
                    onPictureInPicture={() => {
                        if (document.pictureInPictureElement) {
                            dispatchVideoState({
                                type: videoActions.setValue,
                                payload: { pip: false },
                            });
                        } else if (document.pictureInPictureEnabled) {
                            dispatchVideoState({
                                type: videoActions.setValue,
                                payload: { pip: true },
                            });
                        }
                    }}
                    onPlaybackRateChange={(playbackRate) => {
                        dispatchVideoState({
                            type: videoActions.setValue,
                            payload: { playbackRate },
                        });
                    }}
                />
            ) : null}
        </div>
    );
};

function handleRequestFullscreen(element) {
    if (element.requestFullscreen) {
        element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
    } else if (element.webkitEnterFullscreen) {
        element.webkitEnterFullscreen();
    }
}

function checkIsMobile() {
    return /Mobi/i.test(window.navigator.userAgent);
}
