
import React from "react";
import '../Home.scss'
import {Link} from "react-router-dom";

export const JoinUsComponent = () => {

    return (
        <>
            <div id="home-page-joinus">
                <div id="home-page-joinus-text">
                    <h2>Join Us at Rhombus<br></br></h2>
                    <p>
                        <span data-aos="fade-up" data-aos-duration="100">We are looking for people who are driven by a sense of mission. We are recruiting systems-level thinkers and real doers. You will excel in these roles if you are hungry to make a difference and are intellectually bold. And we sure hope that you smile when you hear our clients say, “If we can dream it, Rhombus can do it.”<br></br></span>
                        <span data-aos="fade-up" data-aos-duration="200"><br></br></span>
                        <span data-aos="fade-up" data-aos-duration="400">Come join our cross-disciplinary and world-class team that is delivering game-changing solutions.<br></br></span>
                    </p>
                    <a href={'https://boards.greenhouse.io/rhombuspower'} target="_blank" rel="noopener" className="moreLinks">
                        <div className="loader-line-home"></div>
                        <span className="loader-line" style={{backgroundColor: "#f2cc37"}}></span>
                        <img className="loadArrow" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAArklEQVRIie2UPQ6CQBCFv/Gnk8uIstyImIiiJzE0hiMhu4fZYGJhMVYmdiwYqPjqffN23mQGZmYmwds0984UqkhfbadAbbxuWb2ApUC5ietCBA01WHT+YOfeilwAVTg9nbn16ST4YdskmYrcAUGoom19COmkV6ZDTDoj+iXaPypFCkBRstaZa5eml8EQRo9o9CEHReRtmn+LC5ShxWGCRQvCN8nRW3MecipmZv7nA+bmUNk/H3f1AAAAAElFTkSuQmCC"></img>
                        <span style={{color: "#f2cc37"}}>
                            See Our Open Positions
                        </span>
                    </a>
                </div>
                <div></div>
            </div>
        </>
    )
}
