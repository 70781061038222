import React, { useState } from 'react';
import { Modal } from '@carbon/react';
import ReactPlayer from 'react-player';

import { VideoWithController } from './VideoWithController';

import play from '../assets/img/play--filled--alt.svg';
import './utilStyles/VideoComponentStyles.scss';

const modalStyles = {
    width: '100%',
    height: 'auto',
};

export const Mp4VideoComponent = (props) => {
    const [open, setOpen] = useState(false);

    const openModal = () => {
        setOpen(true);
    };

    const closeModal = () => {
        setOpen(false);
    };

    return (
        <>
            <Modal
                open={open}
                onRequestClose={(e) => closeModal()}
                size="lg"
                className="videoModal"
                passiveModal
            >
                <div className="modalContent" id="modalContent">
                    <VideoWithController
                        playVideo={open}
                        reactPlayerProps={{
                            style: modalStyles,
                            url: props.url,
                            playsinline: true,
                        }}
                    />
                </div>
            </Modal>
            <div className="vid-root" id="vid-root">
                <div className="videoContainer">
                    <div className="background-video">
                        <ReactPlayer
                            config={{
                                file: {
                                    attributes: {
                                        poster: 'assets/Logos/videoThumbnail.png',
                                    },
                                },
                            }}
                            width="100%"
                            height="100%"
                            url={props.url}
                            playIcon={play}
                            volume={0}
                            playsinline
                            playing
                            muted
                            loop
                        />
                    </div>
                </div>
                <div
                    className="overlay"
                    id="overlay"
                    onClick={() => openModal()}
                >
                    <div className="playArea">
                        <div className="circle">
                            <div className="playButton">
                                <img
                                    src={play}
                                    style={{ color: 'white', size: '15px' }}
                                    alt="play button"
                                />
                            </div>
                        </div>
                        <div className="bottomText">See What We're About</div>
                    </div>
                </div>
            </div>
        </>
    );
};
