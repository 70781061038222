import {useState, useEffect} from "react";
import { TextField, EmailField } from '../utils/fields'
import { AnimatedHeaderTitle } from '../utils/AnimatedHeaderTitle'
import { Helmet } from "react-helmet";
import { useLocation, Navigate } from "react-router-dom";
import { Loading } from "@carbon/react";
import axios from 'axios';
import './Contact.scss';


export const Contact = () => {

    const [email, setEmailValue] = useState({value:''})
    const [name, setName] = useState({value:''});
    const [company, setCompany] = useState({value:''});
    const [hearabout, setHearabout] = useState({value:''});
    const [blurredOnce, setBlurredOnce] = useState(false);
    const [message, setMessage] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [navigate, setNavigate] = useState(<div></div>);
  
    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        const payload = {
            name:name.value,
            email:email.value,
            company:company.value,
            hearabout:hearabout.value,
            content:message
        };
        try {
            let res = await axios.post(`/api/email`, payload);
            // setEmailValue({value:""});
            // setName({value:""});
            // setCompany({value:""});
            // setMessage("");
            setNavigate(<Navigate to="/thank-you" state="contact"/>)
        }
        catch(err) {
            // console.log('ee')
        }
        
        // setFormSubmitted(true);
    }
    

    function validateName(name){
        return Boolean(name.match(/^[a-zA-Z\s]+$/))
    }

    //NAME FIELD
    const nameChildProps = {
        invalid:!validateName(name.value),
        placeholder:"NAME",
        value:name.value,
        className:"name-field"
    }
    const nameFieldProps = {
        setState:setName,
    }

    const emailChildProps = {
        invalid:false,
        placeholder:'EMAIL',
        value:email.value,
        invalidText:"Email is Invalid",
        className:'email-field',
    }
    const emailFieldProps = {
        setState:setEmailValue,
        setBlurred: setBlurredOnce,
        blurred: blurredOnce,
        validation:true,
    }
    const companyChildProps = {
        invalid:false,
        placeholder:'COMPANY (OPTIONAL)',
        value:company.value,
        className:'company-field',
    }
    const companyFieldProps = {
        setState:setCompany,
    }
    const hearaboutChildProps = {
        invalid:false,
        placeholder:'HOW DID YOU HEAR ABOUT US? (OPTIONAL)',
        value:hearabout.value,
        className:'hearabout-field',
    }
    const hearaboutFieldProps = {
        setState:setHearabout,
    }
    function formIsInvalid(){
        if(!validateName(name.value) || email.invalid || message.length === 0 || formSubmitted){
            return true;
        }
        return false;
    }
    
    let submitButton = () => {
        if (!formSubmitted) {
            return <button disabled={formIsInvalid()} type="submit">Submit</button>
        }
        else {
            return (
                <button disabled={formIsInvalid()} type="submit">
                    <div className="loading-container">
                        <Loading withOverlay={false} small={true}/>
                    </div>
                </button>
            )
        }
    } 

    return (
        <div id="contact-page">
            <Helmet>
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Contact - Rhombus Power" />
                <meta property="og:url" content="./contact/" />
                <meta property="og:site_name" content="Rhombus Power" />
                <meta property="article:modified_time" content="2022-03-31T12:16:16+00:00" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:label1" content="Est. reading time" />
                <meta name="twitter:data1" content="2 minutes" />
            </Helmet>
            <AnimatedHeaderTitle text="Contact Rhombus"></AnimatedHeaderTitle>
            <div id="contact-page-content">
            <div id="contact-description">
                <div id="contact-slide-in">
                    <h2>Get in touch with our team today</h2>
                    <p>Learn how the team at Rhombus Power can help you.</p>
                </div>
            </div>
            <form onSubmit={handleSubmit}>
                <TextField childProps={nameChildProps} fieldProps={nameFieldProps} />
                <EmailField childProps={emailChildProps} fieldProps={emailFieldProps} />
                <TextField childProps={companyChildProps} fieldProps={companyFieldProps} />
                <TextField childProps={hearaboutChildProps} fieldProps={hearaboutFieldProps} />
                <textarea placeholder="MESSAGE" onChange={e => setMessage(e.target.value)}  value={message} style={{resize:"none"}}></textarea>
                {submitButton()}
            </form>
            {navigate}
            <div id="address-grid" >
                <div className="address">
                    <b>Silicon Valley</b>
                    <p>3200 Hillview Ave</p>
                    <p>Stanford Research Park</p>
                    <p>Palo Alto, CA 94304</p>
                </div>
                <div className="address">
                    <b>Washington DC</b>
                    <p>801 Pennsylvania Ave NW</p>
                    <p>Suite 450</p>
                    <p>Washington, DC 20004</p>
                </div>
            </div>
        </div>
        </div>
    )
}

