import React, { useState } from 'react'
import { Toggle } from  "@carbon/react";

const CookieOptions = ( { setCookie, setOptionsOpen } ) => {
    const [formState, setFormState] = useState({
        functional:true,
        performance:true,
        analytical:true,
        advertisement:true,
        other:true,
    })
    function handleCookieChange(cookie){
        setFormState(form => {
            return {
                ...form,
                [cookie]:!form[cookie]
            }
        })
    }

    function saveOptions(){
        setCookie( 'true', 'cookie_accept');
        for(const cookie in formState){
            setCookie(formState[cookie], `cookie_${cookie}`)
        }
        setOptionsOpen(false)
    }
    return (
        <div id="cookie-settings">
            <div className="cookie-setting">
                <Toggle id="cookie-toggle-necessary" defaultToggled={true} 
                    hideLabel={true} label="Necessary" 
                    readOnly={true} labelText="Necessary Cookies" />
                <p className="cookie-description"> Necessary cookies are absolutely essential for the website to function properly. These cookies ensure basic functionalities and security features of the website, anonymously. </p>
            </div>
            <div className="cookie-setting">
                <Toggle id="cookie-toggle-functional" defaultToggled={true} 
                    hideLabel={true} toggled={formState['functional']} 
                    labelText="Functional Cookies" onClick={() => handleCookieChange('functional')}/>
                <p className="cookie-description">Functional cookies help to perform certain functionalities like sharing the content of the website on social media platforms, collecting feedback, and other third-party features.</p>
            </div>
            <div className="cookie-setting">
                <Toggle id="cookie-toggle-performance" hideLabel={true} 
                    defaultToggled={true} toggled={formState['performance']} 
                    labelText="Performance Cookies" onClick={() => handleCookieChange('performance')}/>
                <p className="cookie-description" >Performance cookies are used to understand and analyze the key performance indexes of the website which helps in delivering a better user experience for the visitors.</p>
            </div>
            <div className="cookie-setting">
                <Toggle id="cookie-toggle-analytical" hideLabel={true} 
                    defaultToggled={true} toggled={formState['analytical']} 
                    labelText="Analytical Cookies" onClick={() => handleCookieChange('analytical')}/>
                <p className="cookie-description">Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc.</p>
            </div>
            <div className="cookie-setting">
                <Toggle id="cookie-toggle-advertisement" hideLabel={true} 
                    defaultToggled={true} toggled={formState['advertisement']} 
                    labelText="Advertisement Cookies" onClick={() => handleCookieChange('advertisement')}/>
                <p className="cookie-description">Advertisement cookies are used to provide visitors with relevant ads and marketing campaigns. These cookies track visitors across websites and collect information to provide customized ads.</p>
            </div>
            <div className="cookie-setting">
                <Toggle id="cookie-toggle-other" hideLabel={true} 
                    defaultToggled={true} toggled={formState['other']} 
                    labelText="Other Cookies" onClick={() => handleCookieChange('other')}/>
                <p className="cookie-description">Other uncategorized cookies are those that are being analyzed and have not been classified into a category as yet.</p>
            </div>
            <button id="save-cookies-button" onClick={saveOptions}>Save</button>
        </div>
    )
}

export default CookieOptions