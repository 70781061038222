import React from 'react';
import './Accordion.scss'
import { infoListData } from './InfoListContent'
import { AccordionComponent } from '.';
import { Col, Container, Row } from 'react-bootstrap';

export const Accordion = () => {

    return (
        <Container>
            <Row id="accordion-list-cont">
                <Col className="accordion-img-div">
                    <div id="accordion-left-img"></div>
                </Col>
                <Col className="items">
                    <AccordionComponent
                        data={infoListData}
                    ></AccordionComponent>
                </Col>
            </Row>
        </Container>
    );
};
