import bloomberg from "../../assets/img/bloomberg.png";
import ai_taliban from "../../assets/img/ai_taliban.jpg";

export const LatestNewsData = [
    {
        img: ai_taliban, 
        date: "July 31, 2024", 
        description: "How America built an AI tool to predict Taliban attacks", 
        category: "News", 
        link:"/article/7", 
        external:true,
        row:true
    },
    {
        img: "https://i.ytimg.com/vi/Lz6Vq4snOUg/hqdefault.jpg", 
        date: "July 07, 2024", 
        description: "Founder and CEO of Rhombus Power, Dr. Anshu Roy, on CNN's First Move with Julia Chatterley", 
        category: "News", 
        link:"https://www.youtube.com/watch?v=Lz6Vq4snOUg", 
        external:true,
        row:true
    },
    {
        img: bloomberg, 
        date: "June 20, 2024", 
        description: "AI Could Even the Odds in the South China Sea", 
        category: "News", 
        link:"/article/6", 
        external:true, 
        row:true
    }
]