/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import {
    PlayFilledAlt,
    PauseFilled,
    VolumeMuteFilled,
    VolumeUpFilled,
    VolumeDownFilled,
    Forward_5,
    Rewind_5,
} from '@carbon/react/icons';

const svgSize = {
    width: 40,
    height: 40,
};

export const VideoAction = ({
    isVisible = false,
    action = '',
    muted,
    playing,
    volume,
}) => {
    let icon = null;
    switch (action) {
        case 'play':
        case 'pause':
            if (playing) {
                icon = <PlayFilledAlt {...svgSize} />;
            } else {
                icon = <PauseFilled {...svgSize} />;
            }
            break;
        case 'mute':
            if (muted) {
                icon = <VolumeMuteFilled {...svgSize} />;
            } else {
                icon = <VolumeUpFilled {...svgSize} />;
            }
            break;
        case 'volumeUp':
            icon = <VolumeUpFilled {...svgSize} />;
            break;
        case 'volumeDown':
            if (volume <= 0) {
                icon = <VolumeMuteFilled {...svgSize} />;
            } else {
                icon = <VolumeDownFilled {...svgSize} />;
            }
            break;
        case 'forward':
            icon = <Forward_5 {...svgSize} />;
            break;
        case 'backward':
            icon = <Rewind_5 {...svgSize} />;
            break;
        default:
            icon = null;
            break;
    }
    return (
        <div className={`video-action p-3 ${isVisible ? 'active' : ''}`}>
            <div className="action-icon">{icon}</div>
        </div>
    );
};
