import React from "react";
import '../About.scss'

export const AtRhombusComponent = () => (
    <>
        <div id="about-page-atrhombus">
            <div className="about-page-atrhombus-text-cont">
                <h2 data-aos="fade-up" data-aos-duration="600">
                    <b>
                        At Rhombus, we know that the future belongs to those who believe in the power of collaboration to drive impact with conviction and focus.
                    </b>
                </h2>
            </div>
            <div className="about-page-atrhombus-text-cont">
                <h2 data-aos="fade-up" data-aos-duration="800">
                    <b>
                        Together, we can and will make the world a stronger, safer, better place. Not by hoping for it, or wishing for it, but through something as timeless as hard work and something as innovative as Artificial Intelligence.
                    </b>
                </h2>
            </div>
        </div>
    </>
)
