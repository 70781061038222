import React from 'react';
import {
    Button,
    TextInput,
    Tooltip,
    OverflowMenu,
    OverflowMenuItem,
} from '@carbon/react';
import {
    PlayFilledAlt,
    PauseFilled,
    VolumeMuteFilled,
    VolumeUpFilled,
    FitToScreen,
} from '@carbon/react/icons';

const menuItems = [
    {
        text: '0.5',
        value: 0.5,
    },
    {
        text: 'Normal',
        value: 1,
    },
    {
        text: '1.5',
        value: 1.5,
    },
];

const formatTime = (seconds) => {
    const min = Math.floor(Number(seconds) / 60);
    const sec = Math.floor(Number(seconds) % 60);

    return `${min}:${sec < 10 ? '0' + sec : sec}`;
};

export const Controller = ({
    className = '',
    volume,
    playing,
    playedSeconds,
    totalSeconds,
    muted,
    playbackRate,
    hideOverflowMenu = false,
    onFocus,
    onBlur,
    onPlay,
    onVolume,
    onMute,
    onSeek,
    onFullscreen,
    onPictureInPicture,
    onPlaybackRateChange,
}) => {
    const [showPlaybackOptions, setShowPlaybackOptions] = React.useState(false);
    const overflowRef = React.useRef(null);

    const volumeNumber = `${Math.floor(volume * 100)}`;
    const currTime = formatTime(playedSeconds);
    const totalTime = formatTime(totalSeconds);

    const timeString = `${currTime}/${totalTime}`;

    return (
        <div
            className={`controller controller-padding ${className}`}
            onFocus={onFocus}
            onBlur={onBlur}
        >
            <div className="d-flex w-100 align-items-center">
                <Tooltip
                    className="cds--icon-tooltip"
                    label={playing ? 'Pause (k)' : 'Play (k)'}
                    align="top-left"
                    enterDelayMs={250}
                    aria-label={
                        playing
                            ? 'Pause keyboard shortcut k'
                            : 'Play keyboard shortcut k'
                    }
                    closeOnActivation
                >
                    {playing ? (
                        <Button
                            size="sm"
                            kind="ghost"
                            iconDescription="Pause"
                            onClick={onPlay}
                        >
                            <PauseFilled />
                        </Button>
                    ) : (
                        <Button
                            size="sm"
                            kind="ghost"
                            iconDescription="Play"
                            onClick={onPlay}
                        >
                            <PlayFilledAlt />
                        </Button>
                    )}
                </Tooltip>
                <div className="d-flex align-items-center">
                    <Tooltip
                        className="cds--icon-tooltip"
                        label={muted ? 'Unmute (m)' : 'Mute (m)'}
                        align="top"
                        enterDelayMs={250}
                        aria-label={
                            muted
                                ? 'Unmute keyboard shortcut m'
                                : 'Mute keyboard shortcut m'
                        }
                        closeOnActivation
                    >
                        <Button
                            size="sm"
                            kind="ghost"
                            iconDescription={muted ? 'Muted' : 'Volume Up'}
                            onClick={onMute}
                        >
                            {muted ? <VolumeMuteFilled /> : <VolumeUpFilled />}
                        </Button>
                    </Tooltip>
                    <Tooltip
                        className="cds--icon-tooltip"
                        label={`Volume ${volumeNumber}%`}
                        align="top"
                        enterDelayMs={250}
                        closeOnActivation
                        aria-valuetext={`${volumeNumber}% volume ${
                            muted ? 'muted' : ''
                        }`}
                        aria-label="volume"
                        aria-valuemin={0}
                        aria-valuemax={100}
                    >
                        <TextInput
                            className="volume-rocker"
                            type="range"
                            id="volume-rocker"
                            labelText=""
                            onChange={onVolume}
                            value={volumeNumber}
                        />
                    </Tooltip>
                </div>
                <Tooltip
                    className="ms-auto cds--icon-tooltip"
                    label="Fullscreen (f)"
                    align="top"
                    enterDelayMs={250}
                    closeOnActivation
                    aria-label="Enter fullscreen"
                    aria-pressed="false"
                >
                    <Button
                        size="sm"
                        kind="ghost"
                        iconDescription="Fullscreen"
                        onClick={onFullscreen}
                    >
                        <FitToScreen />
                    </Button>
                </Tooltip>
                {hideOverflowMenu ? null : (
                    <OverflowMenu
                        direction="top"
                        flipped
                        menuOptionsClass="controller-options"
                        onClose={() => setShowPlaybackOptions(false)}
                        innerRef={overflowRef}
                    >
                        {showPlaybackOptions &&
                            menuItems.map((item) => (
                                <OverflowMenuItem
                                    key={item.text}
                                    itemText={item.text}
                                    className={
                                        item.value === playbackRate
                                            ? 'playback-active'
                                            : ''
                                    }
                                    onClick={() =>
                                        onPlaybackRateChange(item.value)
                                    }
                                />
                            ))}
                        {!showPlaybackOptions ? (
                            <OverflowMenuItem
                                key="playback"
                                itemText="Playback"
                                onClick={() => {
                                    // Required due to Carbon not allowing nested menus
                                    window.requestAnimationFrame(() => {
                                        setShowPlaybackOptions(true);
                                        overflowRef.current?.click?.();
                                    });
                                }}
                            />
                        ) : null}
                        {!showPlaybackOptions ? (
                            <OverflowMenuItem
                                key="pip"
                                itemText="Picture in Picture"
                                onClick={onPictureInPicture}
                                disabled={!document.pictureInPictureEnabled}
                            />
                        ) : null}
                    </OverflowMenu>
                )}
            </div>
            <div className="d-flex align-items-center w-100 ps-2">
                <Tooltip
                    className="w-100 cds--icon-tooltip"
                    label={`${currTime} / ${totalTime}`}
                    align="top"
                    enterDelayMs={250}
                    closeOnActivation
                    aria-valuetext={`progress ${currTime} of ${totalTime}`}
                    aria-label="progress"
                    aria-valuemin={0}
                    aria-valuemax={Math.floor(totalSeconds)}
                >
                    <TextInput
                        className="progress-range"
                        id="progress range"
                        type="range"
                        labelText=""
                        min={0}
                        max={Math.floor(totalSeconds)}
                        value={playedSeconds}
                        onChange={onSeek}
                    />
                </Tooltip>
                <p className="progress-time ms-2 text-nowrap">{timeString}</p>
            </div>
        </div>
    );
};
