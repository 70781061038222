import React from 'react';
import { TextInput } from '@carbon/react';
import { InputValidator } from '../inputValidation';

// Using EmailField as a child component, 
// pass in TextInput attributes via childProps
// and type, validation, and setState properties via fieldProps.
// Activate validation of input by passing {validation:true} 

export const EmailField = ({ childProps, fieldProps }) => {
    const attributes = childProps !== undefined ? {...childProps} : {}
    const misc = fieldProps !== undefined ? {...fieldProps} : {}

    const tempProps = {
        ...attributes, 
        value: attributes.value || '', 
        invalid: misc.validation && attributes.value !== '' ? attributes.invalid : false ,
        id: attributes.id || `email${Math.random().toString()}`,
        labelText: attributes.labelText || 'Email',
        hideLabel: typeof attributes.hideLabel !== 'undefined' ? attributes.hideLabel : true,  
        className: attributes.className || 'my-3',
        placeholder: attributes.placeholder || 'Enter Your Email',
        onChange: (e) => handleChange(e),
        onBlur:(e) => handleBlur(e),
    }

    misc.setState = misc.setState!== undefined ? misc.setState : ()=>{console.log('Pass setState function in fieldProps object')}
    misc.type = misc.type || 'email'
    const handleBlur = (e) => {
        let validate;
        if (misc.validation) {
            validate = InputValidator({ type: misc.type, value: tempProps.value })
            misc.setState(state => {
                return {
                    ...state,
                    invalid: e.target.value==='' ? true: !validate || false 
                }
            })

            if (!fieldProps.blurred) {
                misc.setBlurred(true);
            }
        }
    }
    const handleChange = (e) => {
        let validate = undefined;
        if (fieldProps.blurred && misc.validation) {
            validate = InputValidator({type: misc.type, value: tempProps.value});
        }
        misc.setState(state => {
            return {
                ...state,
                value:e.target.value,
                invalid: validate !== undefined ? e.target.value !== '' ? !validate : true : undefined
            }
        })
    }

    return  <TextInput {...tempProps} /> 
}