import React from 'react';
import {Link, useLocation} from "react-router-dom";


export const HeaderLink = ({name, to, linkKey}) => {
    const location = useLocation()
    let linkClass
    if(location.pathname == to){
        linkClass = "active-link"
    } else {
        linkClass = ""
    }

    return <Link 
        to={to} 
        key={linkKey} 
        className={linkClass}
    >
        {name}
    </Link>
}