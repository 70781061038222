import React from 'react';
import { TextInput } from '@carbon/react';
import { InputValidator } from '../inputValidation';

export const TextField = ({ childProps, fieldProps }) => {
    
    const attributes = childProps !== undefined ? {...childProps} : {}
    const misc = fieldProps !== undefined ? {...fieldProps} : {}

    misc.type = misc.type || 'text'
    misc.setState=misc.setState || function () {console.log('provide setState function via fieldProps')}
    const capsType = misc.type[0].toUpperCase() + misc.type.substring(1)

    const tempProps = {
        ...attributes, 
        value: attributes.value || '',
        invalid: attributes.value !== '' ? attributes.invalid : false,
        invalidText: attributes.invalidText || `This ${misc.type} is invalid`,
        labelText: attributes.labelText || capsType,
        id: attributes.id || `${misc.type}${Math.random().toString()}`, 
        placeholder: attributes.placeholder || `Enter Your ${capsType}`,  
        hideLabel: attributes.hideLabel!==undefined ? attributes.hideLabel : true,
        className: attributes.className || 'my-3',
        onChange: (e) => handleChange(e)
    }

    const handleChange = (e) => {
        let validate
        if (misc.validation) {
            validate = InputValidator({ type: misc.type, value: e.target.value })
        }
        attributes.invalid!==undefined ? 
        misc.setState({
             value:e.target.value, 
             invalid: e.target.value==='' 
                ? true
                : validate !==undefined 
                    ? !validate
                    : false
            })
            : 
        misc.setState(e.target.value)
    }

    return  <TextInput {...tempProps} /> 
}