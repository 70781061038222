import React from "react";
import { paths, footerPaths } from "../config";
import { Link } from "react-router-dom";
import linkedInIcon from './assets/img/linkedin-icon.svg'
export const Footer = () => {

    const navItems = Object.values(paths).map((obj) => {
        if (obj.name !== 'empty') { 
            if (!obj.external) {
                return <Link key={obj.name} to={obj.path}>{obj.name}</Link>
            }
            else {
                return <a key={obj.name} href={obj.path} target="_blank" rel="noopener">{obj.name}</a>
            }
        }
    })
    const footerItems = Object.values(footerPaths).map((obj) => {
        if (obj.name !== 'empty') {
            return <Link key={obj.name} to={obj.path}>{obj.name}</Link>
        }
    })
    const currentYear = new Date().getFullYear()

    return (
        <footer>
            <div id="footer-nav">
                {navItems}
            </div>
            <div id="copyright">
                <a id="rhombus-linkedin-link" target={"_blank"} rel="noopener" href="https://www.linkedin.com/company/rhombus-power/"><img src={linkedInIcon}></img></a>
                {footerItems}
                <p>All Rights Reserved by Rhombus Power Inc. © {currentYear}</p>
            </div>
        </footer>
    )
}