import { ComposedModal, ModalBody, ModalFooter, ModalHeader } from '@carbon/react';
import React from 'react';

export const Modal= ({modal={}, header=false, body={}, footer={hasFooter:false, footerProps:{}}, children=''}) => {

    const modalProps = {
        modal:{
            open: false,
            onClose:()=>{},
            size: 'sm',
            ...modal
        },
        header:{
            closeModal: modal.onClose? modal.onClose: ()=>{},
            title: 'Header Title',
            ...header
        },
        body:{
            children: children,
            hasScrollingContent:false,
            ...body
        },
        footer:{
            onRequestClose: modal.onClose? modal.onClose: ()=>{},
            onRequestSubmit:()=>{},
            primaryButtonText: 'Submit',
            secondaryButtonText: 'Cancel',
            ...footer.footerProps
        },
    }

    const headerElement = ()=>{
        if(header){
            return <ModalHeader {...modalProps.header}/>
        }
    }

    const footerElement = ()=>{
        if(footer.hasFooter){
            return <ModalFooter {...modalProps.footer}/>
        }
    }

    return <>
        <ComposedModal {...modalProps.modal}>
            {headerElement()}
            <ModalBody {...modalProps.body}/>
            {footerElement()}
        </ComposedModal>
    </>
}