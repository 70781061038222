import React from "react";
import "./Home.scss";
import { WinningComponent, JoinUsComponent, AmericaStrongerComponent, Accordion } from "./homeSubs/index";
import { GetInTouch, YellowDivider, Mp4VideoComponent } from "../utils";
import { CarouselComponent } from "./homeSubs/CarouselComponent";
import { LatestNews } from "./homeSubs/LatestNews";
import { Helmet } from "react-helmet";

export const Home = () => (
    <div id="home-page">
        <Helmet>
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Rhombus Power" />
            <meta property="og:url" content="./" />
            <meta property="og:site_name" content="Rhombus Power" />
            <meta property="article:modified_time" content="2022-04-04T17:47:46+00:00" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:label1" content="Est. reading time" />
            <meta name="twitter:data1" content="15 minutes" />
        </Helmet>
        <div id="home-header">
            <h1>AI Powered Decisions at the <em className="em-animate">Speed of Relevance</em></h1>
        </div>
        <Mp4VideoComponent url="videos/Stanley-McChrystal.mp4" />
        <AmericaStrongerComponent/>
        <YellowDivider/>
        <WinningComponent/>
        <CarouselComponent />
        <LatestNews />
        <Accordion/>
        <JoinUsComponent/>
        <GetInTouch color='#0026FF'/>
    </div>
)

