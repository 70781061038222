import React from 'react';
import { CustomLink } from '../../utils/CustomLink';
import '../InTheNewsStyles.scss';

export const ArticleSummary = ({
    customImgStyle,
    img,
    description,
    category = '',
    date = '',
    url,
    external,
    newTab,
    row = '',
}) => {
    let backgroundPosition = 'center';
    if (customImgStyle) {
        backgroundPosition = 'bottom';
    }
    return (
        <div className={`${row ? 'articleContainerRow' : 'articleContainer'}`}>
            <div
                className={
                    row ? 'articleRowImgContainer' : 'articleImgContainer'
                }
            >
                <CustomLink
                    external={external}
                    url={url}
                    newTab={newTab}
                    style={{ height: '100%', width: '100%' }}
                >
                    <div
                        style={{
                            backgroundImage: `url(${img})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition,
                            width: '100%',
                            height: '100%',
                            borderRadius: '5px',
                        }}
                    ></div>
                </CustomLink>
            </div>
            <div
                className={
                    row
                        ? 'articleRowMetadataContainer'
                        : 'articleMetadataContainer'
                }
            >
                <div className={`${row ? 'metaContentRow' : 'metaContent'}`}>
                    <span className="articleCategory">{category}</span>
                        <h4 className="articleDescription">
                            <CustomLink
                                external={external}
                                url={url}
                                newTab={newTab}
                            >
                                {description}
                            </CustomLink>
                        </h4>
                    <span className="articleDate">{date}</span>
                </div>
            </div>
        </div>
    );
};