import React from "react";
import '../About.scss'

export const TodayComponent = () => (
    <div id="about-page-today">
        <p data-aos="fade-in" data-aos-duration="500">
            <span>
                Today, everything that the United States stands for is being contested.<br></br>
            </span>
            <span>America’s adversaries never rest.<br></br></span>
            <span>Neither do we.<br></br></span>
            <span>Threats to our country are only accelerating.<br></br></span>
            <span>So must we all.<br></br></span>
            <span>At Rhombus, we </span>
            <span>are </span>
            <span>– all day, every day, for the United States of America.</span>
        </p>
    </div>
)
