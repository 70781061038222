import React from 'react';
import './App.scss';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import { Header, Footer, CookieBanner, ThankYou } from "./pages";
import { paths, footerPaths, oldPaths } from './config';
import { ArticleOutline } from './pages/ArticlesData/ArticleOutline';

AOS.init({ once: true });

export const App = () => (
    <BrowserRouter forceRefresh={true}>
        <Header paths={paths} />
        <Routes>
            {Object.values(paths).map((obj) => (
                <Route
                    key={obj.path}
                    path={obj.path}
                    element={obj.element}
                />
            ))
            }
            {Object.values(footerPaths).map((obj) => (
                <Route
                    key={obj.path}
                    path={obj.path}
                    element={obj.element}
                />
            ))
            }
            <Route
                exact
                path="article/:id"
                element={<ArticleOutline />}
            />
            <Route 
                exact
                path="thank-you"
                element={<ThankYou />}
            />
            {oldPaths.map((obj) => (
                <Route
                    key={obj.path}
                    path={obj.path}
                    element={<Navigate replace to={obj.newPath} />}
                />
            ))}
        </Routes>
        <CookieBanner />
        <Footer />
    </BrowserRouter>
) 
