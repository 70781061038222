
import React from "react";
import '../Home.scss'
import { Link } from "react-router-dom";


export const WinningComponent = () => {

    return (
        <>
            <div id="home-page-winning">
                <div id="home-page-winning-text">
                    <h2 data-aos="fade-up" data-aos-duration="500"><span>Winning is never preordained.</span></h2>
                    <h2 data-aos="fade-up" data-aos-duration="700">Solutions are not static.</h2>
                    <p>
                        <span data-aos="fade-in" data-aos-duration="100">They are the outcome of a dynamic process. It starts by identifying an evolving threat, devising a strategy to meet it, wargaming and pressure-testing possible solutions, finding the resources, and applying them iteratively and quickly.</span>
                        <span data-aos="fade-in" data-aos-duration="200">And then doing it all over again. And again.</span>
                        <span data-aos="fade-in" data-aos-duration="400">It’s a virtuous cycle for victory that brings us back to a core question:</span>
                        <span data-aos="fade-in" data-aos-duration="500">Can we build an AI-native digital nervous system that accelerates decision-making and elevates organizations to leverage a higher plane of Ambient Intelligence?</span>
                        <span data-aos="fade-in" data-aos-duration="200">Rhombus is transforming our nation’s defense and national security enterprises with Guardian, its Artificial Intelligence platform for strategic, operational, and tactical decision-making at the Speed of Relevance.</span>
                    </p>
                    <Link className="moreLinks" to="/about">
                        <div className="loader-line-home"></div>
                        <span className="loader-line" style={{backgroundColor: "#f2cc37"}}></span>
                        <img className="loadArrow" style={{top:'5px !important'}} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAArklEQVRIie2UPQ6CQBCFv/Gnk8uIstyImIiiJzE0hiMhu4fZYGJhMVYmdiwYqPjqffN23mQGZmYmwds0984UqkhfbadAbbxuWb2ApUC5ietCBA01WHT+YOfeilwAVTg9nbn16ST4YdskmYrcAUGoom19COmkV6ZDTDoj+iXaPypFCkBRstaZa5eml8EQRo9o9CEHReRtmn+LC5ShxWGCRQvCN8nRW3MecipmZv7nA+bmUNk/H3f1AAAAAElFTkSuQmCC"></img>
                        <span style={{color: "#f2cc37"}}>Learn More About Rhombus</span>
                    </Link>
                </div>
            </div>
        </>
    )
}
