import React from "react";
import { inTheNewsData } from "./inTheNewsData";
import { ArticleSummary } from "./inthenewsSubs/ArticleSummary";
import { AnimatedHeaderTitle } from "../utils";
import { Helmet } from "react-helmet";
import "./InTheNewsStyles.scss";

export const InTheNews = () => (
    <>
        <Helmet>
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="In the News - Rhombus Power" />
            <meta property="og:url" content="./in-the-news/" />
            <meta property="og:site_name" content="Rhombus Power" />
            <meta property="article:modified_time" content="2022-01-18T20:16:34+00:00" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:label1" content="Est. reading time" />
            <meta name="twitter:data1" content="1 minute" />
        </Helmet>
        <div className='titleContainer'>
            <div style={{width: "80%"}}>
                <AnimatedHeaderTitle style={{margin: "0px"}} text="Latest News" />
            </div>
            {/* <h1 className='inthenewstitle'>Latest News</h1> */}
        </div>
        <div style={{width: "100%", height: "auto", backgroundColor: "#000000", display: "flex", justifyContent: "center"}}>
            <div id="inthenewsContainer">
                {inTheNewsData.map((obj, i) => (
                    <ArticleSummary 
                        key={i} img={obj.img} description={obj.description} 
                        category={obj.category} date={obj.date} url={obj.link} 
                        external={obj.external} newTab={true} customImgStyle={obj?.customImgStyle}/>
                ))}
            </div>
        </div>
    </>
)

