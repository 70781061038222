export const infoListData = [
    {
      key:0,
      title: "Anticipatory Indications & Warning",
      content1: "What’s out there on the horizon and what lurks just beyond?  It’s never been more critical to anticipate transformative events. Previous generations could only dream of the advantages of AI to connect seemingly unconnected anomalies and search for  patterns, to augment human intelligence, diplomatic and military planning with a technological edge. It’s how we anticipate and even predict coming flashpoints long before they surface.",
      content2: "An effective, innovative AI Central Nervous System can identify gathering trends before they become imminent threats.  A platform can be designed and tasked to provide insights on today’s perplexing problems before they become tomorrow’s crises, through sensing, coordination, action and guidance, all combined to provide early indications and warning (I&W.)"
    },
    {
      key:1,
      title: "Smart Resourcing",
      content1: "Historically, decisions about resources have been made in silos, based on the best available information. With AI making better information available from more sources than ever before, so too can decisions about resources be based on the whole picture across the strategic and operational landscape – not just a siloed perspective or situational snapshot. Integrating AI and organizing complete data sets can inform Smart Resourcing across entire organizations to optimize results.",
      content2: ""
    },
    {
      key:2,
      title: "Global Operations Intelligence Platform",
      content1: "Sharing information across entire systems has never been more critical. One piece of intelligence on one dashboard may appear benign or insignificant, but combined with hundreds of other data points across the system, it can be the diagnostic tool senior leaders need to make decisions earlier while their freedom of action is maximized. Organizing these inputs to create a complete picture is what turns individual human intelligence and satellite and other data into actionable intelligence. It is the technological breakthrough that empowers intelligence analysts to make assessments at the speed of relevance.",
      content2: ""
    },
    {
      key:3,
      title: "Accelerating Deterrence",
      content1: "Information empowers deterrence. To identify any adversaries’ actions and predict future behavior is the key today to planning deterrence tomorrow. Today, technology itself creates opportunities to make more sophisticated, strategic decisions informed by aggregate data streams. Alternatively, the speed of technology and its adoption by current and potential adversaries underscores just how much the margin of error in deterrence is shrinking.",
      content2: ""
    },
    {
      key:4,
      title: "Winning the Competition",
      content1: "Today America is locked in a strategic competition, with multiple adversaries spanning multiple domains – land, air, maritime, cyber, space, and information. The accelerated pace of challenges faced by organizations and their leaders are distributed, and increasingly influenced by threat multipliers including climate change. Most of the technological advancements today reside with the commercial world. At Rhombus, we offer a high functioning, complex digital nervous system that can anticipate threats, accelerate data-informed decisions across domains whether natural or adversary caused, and has the resilient communications backbone to facilitate response coordination pre-emptively. Ground zero in this competition is the battle over AI supremacy. We are investing in new technologies to solve environmental, economic, and societal challenges while also holding aggression at bay.",
      content2: ""
    },
    {
      key:5,
      title: "Winning the Tech Race",
      content1: "The United States maintains a competitive advantage in key areas of innovation and capital. We lead today in research and development, to the tune of $4 billion in government-led unclassified AI-related R&D in 2019 alone. But our real strength is that the United States remains the leading destination for global AI investments. Counting the private sector, our annual investment is closer to $26 billion. There are more than 2,000 American AI firms, including startups and large private companies.",
      content2: "The United States can win the AI race in the long term because the private sector drives AI, and our significant lead in private sector AI investment is an ace in the hole. But to actually win, we must reinforce the vibrant, open innovation ecosystem that fuels the American AI advantage, and create a seamless public-private sector match just as we did during the Cold War. Back then, the space race spun-off game-changing commercial innovations. Today, the opposite can be true:  AI will be developed for civilian use before military applications, and a close partnership would envision collaboration on dual-use technologies. The vibrant private sector is our distinctly American asset but it needs guidance and direction."
    }
];