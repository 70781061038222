import React, { useEffect, useState } from "react";
import parse from 'html-react-parser';
import { useParams } from "react-router-dom";
import { getArticle } from "./articleData";
import { CustomLink } from "../utils/CustomLink";
import { Helmet } from "react-helmet";
import "./ArticleStyles.scss";

export const ArticleOutline = (props) => {
    let { id } = useParams();
    const [data, updateData] = useState({ title: "", date: "", subtitle: "", content: [], linkProps: { external: false, url: "", newTab: "" } })

    useEffect(() => {
        document.title = data.documentTitle ?? data.title;
    })

    useEffect(() => {
        let article = getArticle(id);
        updateData(article);

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [id])

    return (
        <>
            <div className="articleRoot">
                <Helmet>
                    <meta name="description" content={data.metaDescription} />
                </Helmet>
                <div className="titleSection" style={{ backgroundImage: `url(${data.titleImage})`, backgroundSize: "cover", backgroundPosition: "center" }}>
                    <div className="articleTitleContainer">
                        <div className="title">
                            <CustomLink external={false} url={"/in-the-news"} newTab={true}>
                                <div className="articleTitleCategory" >{data.category}</div>
                            </CustomLink>
                            <div style={{ width: "100%", fontWeight: "bold", display: "flex", justifyContent: "center", padding: "20px 0px" }}>
                                <div className="titleContent">
                                    {data.title}
                                </div>
                            </div>
                            <div style={{ width: "100%" }}>{data.date}</div>

                        </div>

                    </div>
                </div>
                <div className="contentSection">
                    <div className="content">
                        <div className="contentSubTitle">
                            {data.subTitle}
                        </div>
                        {data.content.map((para, i) => {
                            return (
                                <div key={i}>
                                    <p className="articleParagraph">{parse(para)}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <CustomLink external={data.linkProps.external} url={data.linkProps.url} newTab={data.linkProps.newTab} style={{ height: "100%", width: "100%" }} >
                    <div className="nextSection" style={{ backgroundImage: `url(${data.nextImage})` }}>
                        <div className="nextSectionContainer">
                            <div className="nextContent">
                                <div style={{ marginBottom: "15px" }}>Next Post</div>
                                <div style={{ fontSize: "20px" }}>{data.nextTitle}</div>
                            </div>
                        </div>
                    </div>
                </CustomLink>
            </div>
        </>
    )
}
