import mcchrystal from "../../assets/img/gen-stan-mchrystal.jpeg";
import mcmaster from "../../assets/img/hrmcmaster.jpeg";
import flournoy from "../../assets/img/michele-flournoy.jpeg";
import work from "../../assets/img/bobwork.jpeg";
import fargo from "../../assets/img/thomasfargo.jpeg";
import holmes from "../../assets/img/mikeholmes.jpeg";
import moran from "../../assets/img/williammoran.jpeg";

export const carouselData = [

    {
        img: mcchrystal,
        title: "Gen Stan McChrystal (Ret.)",
        description: "Can we use Artificial Intelligence to anticipate an adversary's next move and determine our own?",
        link: "https://castbox.fm/app/castbox/player/id2910334/id341259166?v=8.22.11&autoplay=0",
        linkName: "Listen to the Podcast with Gen Stan McChrystal"
    },
    {
        img: mcmaster,
        title: "Lt Gen H R McMaster (Ret.)",
        subTitle: "Former National Security Advisor",
        description: "Can we quantitatively uncover the interplay of soft and hard power in great power competition? Can we do it at the cadence enabled by a digital nervous system?",
    },
    {
        img: flournoy,
        title: "Michéle Flournoy",
        description: "Can we build an AI Decision Advantage platform that empowers the DoD & IC to compete and win from within?"
    },
    {
        img: work,
        title: "The Honorable Robert O. Work",
        subTitle: "Former Deputy Secretary of Defense",
        description: "Can AI help us maintain a technologically superior military that enables us to lead with diplomatic and economic influence?<br/><br/>Can AI help us anticipate a rapidly evolving strategic playing field more clearly, in real-time?"
    },
    {
        img: fargo,
        title: "Adm Thomas Fargo (Ret.)",
        description: "Can Artificial Intelligence provide us foresight so we know what we'll need, before we need it?"
    },
    {
        img: holmes,
        title: "Gen Mike Holmes (Ret.)",
        description: "Can we use AI to understand how resourcing decisions impact warfighting capability against a pacing threat?"
    },
    {
        img: moran,
        title: "Adm William F. Moran (Ret.)",
        description: "Can AI be used iteratively and quickly arrive at an optimal data strategy for organizations?"
    }


];