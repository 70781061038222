import React, { useEffect, useState } from "react";
import { Button } from "@carbon/react";
import { Modal } from "../utils";
import "./CookieBanner.scss";
import { PrivacyPolicy } from "../PrivacyPolicy/PrivacyPolicy";
import CookieOptions from "./CookieOptions";

const setCookie = (cValue, cName, exDays = 7) => {
  const d = new Date();
  d.setTime(d.getTime() + exDays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cName}=${cValue};${expires};path=/`;
};

const getCookie = (cName) => {
  const name = `${cName}=`;
  // console.log('cookie:', document.cookie)
  let cArray = document.cookie.split(";");
  for (const property of cArray) {
    let prop = property;
    while (prop === " ") {
      prop = prop.substring(1);
    }
    if (prop.includes(name) === true) {
      return prop.substring(name.length, prop.length);
    }
  }
  return "";
};

export const CookieBanner = () => {
  const [bannerOpen, setBannerOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);

  const acceptAll = () => {
    setCookie("true", "cookie_accept");
    setBannerOpen(false);
  };
  const openOptions = () => {
    setOptionsOpen(true);
    setBannerOpen(false);
  };
  useEffect(() => {
    if (getCookie("cookie_accept") === "true") {
      setBannerOpen(false);
    } else {
      setBannerOpen(true);
    }
  }, []);

  return (
    <>
      <Modal
        modal={{
          open: modalOpen,
          onClose: () => setModalOpen(false),
        }}
        header={{
          title: "",
        }}
        footer={{
          hasFooter: false,
        }}
        children={<PrivacyPolicy />}
      />
      <Modal
        backdrop="static"
        modal={{
          open: optionsOpen,
          onClose: () => false,
          id: "options-modal",
        }}
        header={{
          title: "Cookie Options",
        }}
        footer={{
          hasFooter: false,
        }}
        children={
          <CookieOptions
            setCookie={setCookie}
            setOptionsOpen={setOptionsOpen}
          />
        }
      />
      <div
        id="cookie-banner"
        style={bannerOpen ? { display: "flex" } : { display: "none" }}
      >
        <p>
          We use cookies on our website to give you the most relevant experience
          by remembering your preferences and repeat visits. By clicking “Accept
          All”, you consent to the use of ALL cookies as described in our{" "}
          <span
            onClick={() => {
              setModalOpen(true);
            }}
          >
            Privacy Policy
          </span>
          .
        </p>
        <div id="cookie-buttons">
          <button id="cookie-options-button" onClick={openOptions}>
            Cookie Options
          </button>
          <button id="accept-cookies-button" onClick={acceptAll}>
            Accept
          </button>
        </div>
      </div>
    </>
  );
};
